var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "features-area pb-100" }, [
    _c("div", { staticClass: "container" }, [
      _c("div", { staticClass: "row pb-5" }, [
        _c("div", { staticClass: "col-lg-4 col-md-4 col-sm-4 col-xs-4" }, [
          _c(
            "a",
            { attrs: { href: this.$i18n.bindLang("/visitors-exhibition") } },
            [
              _c(
                "div",
                {
                  staticClass: "single-features wow fadeInUp",
                  attrs: { "data-wow-delay": "0.5s" }
                },
                [
                  _vm._m(0),
                  _c("div", { staticClass: "text-center features-row-title" }, [
                    _c("h2", [_vm._v(_vm._s(_vm.$t("features.col_1")))]),
                    _c("hr", { staticClass: "features-hr-s" })
                  ]),
                  _c("div", {
                    staticClass: "text-center m-3 features-row-content"
                  }),
                  _vm._m(1)
                ]
              )
            ]
          )
        ]),
        _c("div", { staticClass: "col-lg-4 col-md-4 col-sm-4 col-xs-4" }, [
          _c("a", { attrs: { href: this.$i18n.bindLang("/rental-venue") } }, [
            _c(
              "div",
              {
                staticClass: "single-features wow fadeInUp",
                attrs: { "data-wow-delay": "1s" }
              },
              [
                _vm._m(2),
                _c("div", { staticClass: "text-center features-row-title" }, [
                  _c("h2", [_vm._v(_vm._s(_vm.$t("features.col_2")))]),
                  _c("hr", { staticClass: "features-hr-s" })
                ]),
                _c("div", {
                  staticClass: "text-center m-3 features-row-content"
                }),
                _vm._m(3)
              ]
            )
          ])
        ]),
        _c("div", { staticClass: "col-lg-4 col-md-4 col-sm-4 col-xs-4" }, [
          _c("a", { attrs: { href: this.$i18n.bindLang("/news") } }, [
            _c(
              "div",
              {
                staticClass: "single-features wow fadeInUp",
                attrs: { "data-wow-delay": "1.5s" }
              },
              [
                _vm._m(4),
                _c("div", { staticClass: "text-center features-row-title" }, [
                  _c("h2", [_vm._v(_vm._s(_vm.$t("features.col_3")))]),
                  _c("hr", { staticClass: "features-hr-s" })
                ]),
                _c("div", {
                  staticClass: "text-center m-3 features-row-content"
                }),
                _vm._m(5)
              ]
            )
          ])
        ])
      ])
    ]),
    _c("div", { staticClass: "container wow fadeInUp" }, [
      _c("div", { staticClass: "row text-center align-items-start" }, [
        _c("div", { staticClass: "col-lg-5 features-title-title" }, [
          _c("span", {
            staticClass: "features-title-box",
            domProps: { innerHTML: _vm._s(_vm.$t("welcome.title")) }
          }),
          _c("hr", { staticClass: "features-hr", attrs: { size: "5" } })
        ]),
        _c("div", { staticClass: "col-lg-7 text-left" }, [
          _c("p", { staticClass: "features-title-content" }, [
            _vm._v(" " + _vm._s(this.$t("features.content")) + " ")
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "text-center features-icon" }, [
      _c("img", {
        attrs: {
          src: require("assets/project_images/img/visiting-icon.png"),
          alt: "image"
        }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "text-center features-row-play" }, [
      _c("i", { staticClass: "fas fa-play-circle" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "text-center features-icon" }, [
      _c("img", {
        attrs: {
          src: require("assets/project_images/img/organizing-icon.png"),
          alt: "image"
        }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "text-center features-row-play" }, [
      _c("i", { staticClass: "fas fa-play-circle" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "text-center features-icon" }, [
      _c("img", {
        attrs: {
          src: require("assets/project_images/img/mediacenter-icon.png"),
          alt: "image"
        }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "text-center features-row-play" }, [
      _c("i", { staticClass: "fas fa-play-circle" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }