var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "partner-area ptb-64" }, [
      _c("div", { staticClass: "container wow fadeInUp" }, [
        _c(
          "div",
          { staticClass: "partner-slider-six owl-carousel owl-theme" },
          [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-lg-6" }, [
                _c("div", { staticClass: "partner-item" }, [
                  _c(
                    "a",
                    {
                      attrs: {
                        href:
                          "https://www.interplan.group/en/interplan-go-beyond",
                        target: "_blank"
                      }
                    },
                    [
                      _c("img", {
                        attrs: {
                          src: require("assets/project_images/pic/logo-interplan.png"),
                          alt: "Image"
                        }
                      })
                    ]
                  )
                ])
              ]),
              _c("div", { staticClass: "col-lg-6 text-center" }, [
                _c("div", { staticClass: "partner-item" }, [
                  _c(
                    "a",
                    {
                      attrs: {
                        href: "https://www.hakuhodo-global.com",
                        target: "_blank"
                      }
                    },
                    [
                      _c("img", {
                        attrs: {
                          src: require("assets/project_images/pic/logo-hakuodo.png"),
                          alt: "Image"
                        }
                      })
                    ]
                  )
                ])
              ])
            ])
          ]
        )
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }