<template>
  <div class="features-area pb-100">
    <div class="container">
      <div class="row pb-5">
        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
          <a :href="this.$i18n.bindLang('/visitors-exhibition')">
            <div class="single-features wow fadeInUp" data-wow-delay="0.5s">
              <div class="text-center features-icon">
                <img
                  src="~assets/project_images/img/visiting-icon.png"
                  alt="image"
                />
              </div>

              <div class="text-center features-row-title">
                <h2>{{ $t("features.col_1") }}</h2>
                <hr class="features-hr-s" />
              </div>
              <div class="text-center m-3 features-row-content">
                <!--<p>Come and report</p>-->
              </div>
              <div class="text-center features-row-play">
                <i class="fas fa-play-circle"></i>
              </div>
            </div>
          </a>
        </div>

        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
          <a :href="this.$i18n.bindLang('/rental-venue')">
            <div class="single-features wow fadeInUp" data-wow-delay="1s">
              <div class="text-center features-icon">
                <img
                  src="~assets/project_images/img/organizing-icon.png"
                  alt="image"
                />
              </div>
              <div class="text-center features-row-title">
                <h2>{{ $t("features.col_2") }}</h2>
                <hr class="features-hr-s" />
              </div>
              <div class="text-center m-3 features-row-content">
                <!--<p>Come and report</p>-->
              </div>
              <div class="text-center features-row-play">
                <i class="fas fa-play-circle"></i>
              </div>
            </div>
          </a>
        </div>

        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
          <a :href="this.$i18n.bindLang('/news')">
            <div class="single-features wow fadeInUp" data-wow-delay="1.5s">
              <div class="text-center features-icon">
                <img
                  src="~assets/project_images/img/mediacenter-icon.png"
                  alt="image"
                />
              </div>
              <div class="text-center features-row-title">
                <h2>{{ $t("features.col_3") }}</h2>
                <hr class="features-hr-s" />
              </div>
              <div class="text-center m-3 features-row-content">
                <!--<p>Come and report</p>-->
              </div>
              <div class="text-center features-row-play">
                <i class="fas fa-play-circle"></i>
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>
    <div class="container wow fadeInUp">
      <div class="row text-center align-items-start">
        <div class="col-lg-5 features-title-title">
          <span class="features-title-box" v-html="$t('welcome.title')"> </span>
          <hr size="5" class="features-hr" />
        </div>
        <div class="col-lg-7 text-left">
          <p class="features-title-content">
            {{ this.$t("features.content") }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Features",
  data() {
    return {
      navTitleVisitor: "參觀訪客",
      navTitleOrganizers: "主辦單位",
      navTitlePress: "新聞媒體",
      navTitleCompany: "關於我們",
      navContentVisitor: "給你一個難以忘懷的體驗",
      navContentOrganizers: "讓我們一起舉行活動",
      navContentPress: "資訊的來源",
      navContentCompany: "第一座臨海的展覽會館",
    };
  },
};
</script>

<style scoped>
.features-area .container {
  max-width: 1000px;
}
.btn-warning {
  color: #212529;
  background-color: #ffff00;
  border-color: #cccc00;
}
.features-title-title {
  text-align: center;
}
.features-title-box {
  text-align: center;
  font-size: 4rem;
  font-weight: bold;
  line-height: 100px;
}
.features-title-content {
  padding-top: 13px;
}
.features-hr,
.features-hr-s {
  border: 2px solid #cccc00;
}
.features-hr-s {
  width: 60px;
}
.features-area {
  background-color: #eff4f5;
}
.features-icon img {
  width: 80px;
}

.features-area .single-features {
  background-color: #ffffff;
  transition: all 0.5s;
  border-right: transparent;
}

.single-features {
  padding: 45px 0;
  border-radius: 5px;
}
.features-area a {
  display: block;
  color: #212121;
}
.single-features h3 {
  color: #000034;
  position: relative;
  padding-left: 45px;
  margin-bottom: 20px;
  transition: all 0.5s;
}

.single-features p {
  color: #000034;
  margin-bottom: 0;
  font-family: Arial, Helvetica, sans-serif;
}

.features-icon i {
  font-size: 42px;
}
.features-row-play {
  font-size: 24px;
}
.features-area .single-features:hover::after {
  background-image: -webkit-gradient(
    linear,
    left top,
    right bottom,
    from(#ffff00),
    to(#ffff00)
  );
  background-image: linear-gradient(to bottom right, #ffff00, #ffff00);
}

.single-features::after {
  background-image: -webkit-gradient(
    linear,
    left top,
    right bottom,
    from(#ffff00),
    to(#ffff00)
  );
  background-image: linear-gradient(to bottom right, #ffff00, #ffff00);
}

/* 下底線 */
.single-features::before {
  background-color: #ffffff !important;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .features-area {
    margin-top: -50px !important;
    padding-top: 0 !important;
  }
}

@media only screen and (max-width: 767px) {
  .features-row-title h2 {
    font-size: 20px;
  }
  .features-title-title {
    text-align: center;
  }
  .features-title-box {
    font-size: 3rem;
    font-weight: bold;
  }
  .features-area {
    margin-top: -100px !important;
    padding-top: 0px !important;
  }

  .single-features {
    padding: 20px 0;
  }
}

@media only screen and (max-width: 568px) {
  .features-area .container .row .col-xs-4 {
    width: 33% !important;
    padding: 0 8px;
  }

  .features-hr {
    display: none !important;
  }

  .features-btn {
    text-align: center !important;
  }

  .features-row-title h2 {
    font-size: 18px;
  }

  .features-row-content p {
    display: none !important;
  }

  .features-row-play i {
    display: none !important;
  }
}
</style>
