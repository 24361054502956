<template>
<kinesis-container>
    <div class="banner-area banner-area-three">
        <div class="d-table">
            <div class="d-table-cell">
                <kinesis-element :strength="20">
                <div class="container">
                    <div class="banner-text">
                        <p class="banner-text-color" v-html="$t('banner.largeTitle_1')"></p>
                        <p class="banner-text2-color mt-2">{{ $t('banner.largeTitle_2') }}</p>
                    </div>
                </div>
                </kinesis-element>
            </div>
        </div>
        <div class="banner-shape-1">
            <video autoplay muted loop class="video-block">
                <source src="~assets/project_images/kec_index_video.mp4" type="video/mp4">
            </video>
        </div>

        <div class="mobile-banner-image">
            <div class="banner-shape-1">
                <img class="banner-image" src="~assets/project_images/pic/KEC-inside-blued.jpg" alt="Image">
            </div>
        </div>

    </div>
</kinesis-container>
</template>

<script>
    export default {
        name: 'Banner',
        methods: {
            goAnchor(selector) {
                let element = document.querySelector(selector);
                let topOfElement = element.offsetTop - 100;
                let scrollToTop = window.setInterval(function () {
                    var pos = window.pageYOffset;
                    if (pos <= topOfElement) {
                        window.scrollTo(0, pos + 20);
                    } else {
                        window.clearInterval(scrollToTop);
                    }
                }, 16);
            },
        }
    }
</script>

<style>

video {
    mix-blend-mode: overlay;
    min-width: 100vw;
    min-height: 100vh;
}

.banner-image {
    object-fit: cover;
    height: 800px !important;
}

.banner-down-image {
    width: 50px !important;
    padding-bottom: 100px;
}
.banner-area.banner-area-three {
    height: 100vh;
}
.banner-text, .banner-area .banner-text p {
    /* margin-top: 15% !important; */
    font-family: "Noto Sans TC";
    font-weight: bold;
    font-size: 80px;
    line-height: 80px;
    margin-bottom: 0px;
    margin-left: 10px;
}

.banner-text2-color {
    font-size: 18px !important;
    color: #000034 !important;
    line-height: 30px !important;
    text-shadow: 0px 0px 5px #fff;
}

.mobile-banner-image {
    display: none;
}

.banner-text-color {
    color: #ffff00 !important;
    font-family: 'Noto Sans TC';
    letter-spacing: 5px !important;
    text-shadow: 0px 0px 8px rgb(37, 37, 37);
}

.banner-text-color span , .banner-area .banner-text span{
    color: #ffff00 !important;
    font-family: 'Noto Sans TC';
    letter-spacing: 5px !important;
    -webkit-text-stroke: 1px #ffff00;
    -webkit-text-fill-color: transparent;
    font-size: 80px;
    color: #fff;
    font-style: italic;
    margin-left: -10px;
    margin-top: 10px;
}

.banner-area.banner-area-three .banner-text {
    margin: -30px auto 0;
    text-align: left;
}

.banner-area.banner-area-three .banner-shape-1 {
    -webkit-animation: none;
    animation: none;
    /*background-color: #836effa6;*/
}

.banner-area.banner-area-three {
    background-image: none;
}

.banner-btn-style:hover {
    cursor: pointer;
}

.banner-area .banner-text {
    min-width: 100%;
}


@media (max-width: 1200px) {
    /* Extra large devices (large desktops, 1200px and down) */
}

@media (max-width: 992px) {

    /* Large devices (desktops, 992px and down) */
    .video-block {
        object-fit: cover !important;
        z-index: -100 !important;
    }

    .banner-area.banner-area-three {
        height: 100%;
        padding-top: 0 !important;
        padding-bottom: 0 !important;
    }
}

@media (max-width: 768px) {

    /*  Medium devices (tablets, 768px and down) */
    .banner-area.banner-area-three {
        padding-top: 150px;
        min-height: 70vh;
    }

    .banner-text-color {
        font-size: 40px !important;
        /*----by wei---*/
        line-height: 60px !important;
        /*----by wei---*/
        color: #ffff00 !important;
        margin: 50px auto auto auto !important;
        font-family: 'Noto Sans TC';
        /*----by wei---*/
        letter-spacing: 5px !important;
        /*----by wei---*/
    }

    .banner-text-color span {
        font-size: 40px !important;
        line-height: 40px !important;
        color: #ffff00 !important;
        margin: 0 auto !important;
        font-family: 'Noto Sans TC';
        letter-spacing: 1px !important;
        -webkit-text-stroke: 1px #ffff00;
        -webkit-text-fill-color: transparent;
        color: #fff;
        font-style: italic;
    }

    .banner-text2-color {
        font-size: 16px !important;
        color: #000034 !important;
        text-shadow: 0px 0px 10px #fff;
    }
}

@media (max-width: 576px) {

    /*  Small devices (landscape phones, 576px and down) */
    .mobile-banner-image {
        display: block;
    }

    .video-block {
        display: none;
    }
}


</style>