<template>
    <div class="partner-area ptb-64">
        <div class="container wow fadeInUp">
            <div class="partner-slider-six owl-carousel owl-theme">
                <div class="row">
                    <div class="col-lg-6">
                        <div class="partner-item">
                            <a href="https://www.interplan.group/en/interplan-go-beyond" target="_blank">
                                <img src="~assets/project_images/pic/logo-interplan.png" alt="Image">
                            </a>
                        </div>
                    </div>
                    <div class="col-lg-6 text-center">
                        <div class="partner-item">
                            <a href="https://www.hakuhodo-global.com" target="_blank">
                                <img src="~assets/project_images/pic/logo-hakuodo.png" alt="Image">
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    export default {
        name: 'Partner',
    }
</script>

<style>
    .partner-area .container {
        max-width: 1400px;
    }
    .ptb-64 {
        padding-top: 65px;
        padding-bottom: 45px;
    }

</style>