var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "performance-area bottom-image-text" }, [
    _c(
      "div",
      { staticClass: "container" },
      [
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col-lg-12 col-sm-6 align-items-start pb-5" },
            [
              _c(
                "h2",
                {
                  staticClass: "title text-uppercase text-center",
                  attrs: { "aria-hidden": "true", role: "document" }
                },
                [_vm._v(" " + _vm._s(_vm.$t("News.title")) + " ")]
              )
            ]
          )
        ]),
        _c(
          "div",
          { staticClass: "single-security-wrap owl-carousel owl-theme" },
          [
            _c(
              "carousel",
              {
                ref: "my-carousel",
                attrs: {
                  autplay: true,
                  loop: true,
                  paginationEnabled: true,
                  autoplaySpeed: true,
                  autoplayTimeout: 500,
                  navigationEnabled: true,
                  navigationNextLabel: "",
                  navigationPrevLabel: "",
                  perPage: _vm.showPageNumber
                }
              },
              _vm._l(_vm.newsList, function(item, index) {
                return _c(
                  "slide",
                  { key: index, attrs: { "data-index": "0" } },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "single-blog-post",
                        on: {
                          click: function($event) {
                            return _vm.showNewsModel(
                              item.announcement_message_id
                            )
                          }
                        }
                      },
                      [
                        _c("div", { staticClass: "event-border" }, [
                          _c("p", { staticClass: "carousel-date-style" }, [
                            _vm._v(" " + _vm._s(item.post_date) + " ")
                          ]),
                          _c("p", { staticClass: "news-title" }, [
                            _vm._v(" " + _vm._s(item.message_title) + " ")
                          ])
                        ])
                      ]
                    )
                  ]
                )
              }),
              1
            )
          ],
          1
        ),
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col-lg-12 col-sm-6 align-items-start text-center" },
            [
              _c(
                "a",
                { attrs: { href: "news" } },
                [
                  _c("b-button", { attrs: { variant: "warning" } }, [
                    _vm._v(_vm._s(_vm.$t("News.moreButton")))
                  ])
                ],
                1
              )
            ]
          )
        ]),
        _c("NewsModel", {
          attrs: { newsId: 1, NewsModelList: _vm.NewsModelList }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }