<template>
  <b-carousel id="carousel-1" v-model="slide" :interval="4000" controls indicators background="#ababab"
      img-width="1024" img-height="600" style="text-shadow: 1px 1px 2px #333;" @sliding-start="onSlideStart"
      @sliding-end="onSlideEnd" >
      
      <!-- Text slides with image -->
      <a :href="advertisement.url" target="_blank" :class="[{ 'noneEvent': advertisement.url == '' }]" v-for="advertisement in advertisements" :key="advertisement.advertisement_picture_id">
        <b-carousel-slide :img-src="advertisement.path" ></b-carousel-slide>
      </a>
    </b-carousel>
    
  
</template>

<script>
export default {
  data() {
    return {
      slide: 0,
      sliding: null,
      advertisementApiUrl: `${process.env.VUE_APP_API_URL}/advertisement/picture_list`,
      advertisements: []
    };
  },
  mounted() {
    this.getAdvertisement();

  },
  methods: {
    async getAdvertisement() {
      const api = this.advertisementApiUrl + `?advertisement_id=4&language_id=0`;
      await this.axios.get(api).then((result) => {
        this.advertisements = result.data;

      });
    },
    onSlideStart() {
      this.sliding = true;
    },
    onSlideEnd() {
      this.sliding = false;
    },
  },
};
</script>

<style scoped>
#carousel-1 a {
  display: block;
}
.carousel-indicators li {
  width: 10px !important;
  height: 10px !important;
  border-radius: 50% !important;
}
.carousel-item img {
  height: 400px;
}

@media (max-width: 568px) {
  #carousel-1 .carousel-inner .carousel-item img {
    aspect-ratio: 1024 / 720;
    object-fit: cover;
  }
}
.noneEvent {
  pointer-events:none;
}
</style>
