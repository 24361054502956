var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("NavbarStyle"),
      _c("Banner"),
      _c("Features"),
      _c("HomeImageCarousel"),
      _vm._v(" "),
      _c("Event"),
      _c("News"),
      _c("Partner"),
      _c("FooterTop"),
      _c("BacktopButton")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }