var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("kinesis-container", [
    _c("div", { staticClass: "banner-area banner-area-three" }, [
      _c("div", { staticClass: "d-table" }, [
        _c(
          "div",
          { staticClass: "d-table-cell" },
          [
            _c("kinesis-element", { attrs: { strength: 20 } }, [
              _c("div", { staticClass: "container" }, [
                _c("div", { staticClass: "banner-text" }, [
                  _c("p", {
                    staticClass: "banner-text-color",
                    domProps: {
                      innerHTML: _vm._s(_vm.$t("banner.largeTitle_1"))
                    }
                  }),
                  _c("p", { staticClass: "banner-text2-color mt-2" }, [
                    _vm._v(_vm._s(_vm.$t("banner.largeTitle_2")))
                  ])
                ])
              ])
            ])
          ],
          1
        )
      ]),
      _c("div", { staticClass: "banner-shape-1" }, [
        _c(
          "video",
          {
            staticClass: "video-block",
            attrs: { autoplay: "", muted: "", loop: "" },
            domProps: { muted: true }
          },
          [
            _c("source", {
              attrs: {
                src: require("assets/project_images/kec_index_video.mp4"),
                type: "video/mp4"
              }
            })
          ]
        )
      ]),
      _c("div", { staticClass: "mobile-banner-image" }, [
        _c("div", { staticClass: "banner-shape-1" }, [
          _c("img", {
            staticClass: "banner-image",
            attrs: {
              src: require("assets/project_images/pic/KEC-inside-blued.jpg"),
              alt: "Image"
            }
          })
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }