<template>
  <div class="performance-area bottom-image-text">
    <div class="container">
      <div class="row">
        <div class="col-lg-12 col-sm-6 align-items-start pb-5">
          <h2
            class="title text-uppercase text-center"
            aria-hidden="true"
            role="document"
          >
            {{ $t("News.title") }}
          </h2>
        </div>
      </div>

      <div class="single-security-wrap owl-carousel owl-theme">
        <carousel
          ref="my-carousel"
          :autplay="true"
          :loop="true"
          :paginationEnabled="true"
          :autoplaySpeed="true"
          :autoplayTimeout="500"
          :navigationEnabled="true"
          navigationNextLabel=""
          navigationPrevLabel=""
          :perPage="showPageNumber"
        >
          <slide data-index="0" v-for="(item, index) in newsList" :key="index">
            <div
              class="single-blog-post"
              @click="showNewsModel(item.announcement_message_id)"
            >
              <div class="event-border">
                <p class="carousel-date-style">
                  {{ item.post_date }}
                </p>
                <p class="news-title">
                  {{ item.message_title }}
                </p>
              </div>
            </div>
          </slide>
        </carousel>
      </div>
      <div class="row">
        <div class="col-lg-12 col-sm-6 align-items-start text-center">
          <a href="news">
            <b-button variant="warning">{{ $t("News.moreButton") }}</b-button>
          </a>
        </div>
      </div>
      <NewsModel :newsId="1" :NewsModelList="NewsModelList" />
    </div>
  </div>
</template>

<script>
import DateFormat from "dateformat";
import NewsModel from "components/DefaultHome/KECC/Common/NewsModal";
import { Carousel, Slide } from "vue-carousel";

export default {
  name: "News",
  components: {
    Carousel,
    Slide,
    NewsModel,
  },
  data() {
    return {
      newsList: null,
      showPageNumber: window.innerWidth >= 991 ? 4 : 1,
      NewsModelList: [],
      waitOnCallModel: false,
      apiUrl: `${process.env.VUE_APP_API_URL}/announcement/`,
    };
  },
  mounted() {
    const that = this;
    window.addEventListener("resize", () => {
      that.showPageNumber = window.innerWidth >= 991 ? 4 : 1;
    });
    let api = `${this.apiUrl}message_list?announcement_id=5&language_id=${this.$i18n.locale}&auto_replace=false&announcement_group_id=0&limit=12&offset=0&filters[is_hidden]=0`;
    this.axios.get(api).then((response) => {
      for (let index in response.data["list"]) {
        response.data["list"][index].post_date = DateFormat(
          new Date(
            Date.parse(
              response.data["list"][index].post_date.replace(/-/g, "/")
            )
          ),
          "yyyy-mm-dd"
        );
      }
      this.newsList = response.data["list"];
    });
    this.waitOnCallModel = true;
  },
  methods: {
    async showNewsModel(message_id) {
      this.$root.$emit("bv::show::modal", "modal-news-1");
      let apiUrl = `${this.apiUrl}message?announcement_message_id=${message_id}&language_id=${this.$i18n.locale}&language_format=value`;
      await this.axios.get(apiUrl).then((response) => {
        this.NewsModelList = response.data;
      });
    },
  },
};
</script>

<style scoped>
.single-blog-post {
  cursor: pointer;
}
.bottom-image-text {
  padding-top: 50px;
  padding-bottom: 70px;
  background-image: none;
  background-color: #eff4f5 !important;
  background-image: none !important;
  background-repeat: no-repeat;
  background-position: top;
}

.performance-area .container {
  max-width: 1400px;
}

.performance-area::before {
  background-color: transparent;
}

.performance-area
  .single-security-wrap.owl-theme
  .VueCarousel-dot-container
  .VueCarousel-dot.VueCarousel-dot--active {
  background-image: linear-gradient(to bottom right, #007fff, #007fff);
  width: 20px !important;
}

.event-border {
  border-left: 2px solid #ffff00;
  padding: 0 40px 0 40px !important;
  box-sizing: border-box;
  color: #000066;
}

.VueCarousel-slide:first-child .event-border {
  border-left: none;
}

.carousel-date-style {
  font-size: 22px;
  color: #cccc00;
}

.text-uppercase {
  color: #000033;
  font-weight: bold;
}
@media (max-width: 1200px) {
  /* Extra large devices (large desktops, 1200px and down) */
  .performance-area .container {
    max-width: 900px;
  }
}

@media (max-width: 992px) {
  /* Large devices (desktops, 992px and down) */
  .performance-area .container {
    max-width: 600px;
  }
  .event-border {
    border-left: none;
  }
}

@media (max-width: 768px) {
  /*  Medium devices (tablets, 768px and down) */
  .performance-area .container {
    max-width: 450px;
  }
}

@media (max-width: 576px) {
  /*  Small devices (landscape phones, 576px and down) */
  .performance-area .container {
    max-width: 400px;
  }
}
@media (max-width: 375px) {
  /*  Small devices (landscape phones, 576px and down) */
  .performance-area .container {
    max-width: 320px;
  }
}
</style>
