var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-carousel",
    {
      staticStyle: { "text-shadow": "1px 1px 2px #333" },
      attrs: {
        id: "carousel-1",
        interval: 4000,
        controls: "",
        indicators: "",
        background: "#ababab",
        "img-width": "1024",
        "img-height": "600"
      },
      on: { "sliding-start": _vm.onSlideStart, "sliding-end": _vm.onSlideEnd },
      model: {
        value: _vm.slide,
        callback: function($$v) {
          _vm.slide = $$v
        },
        expression: "slide"
      }
    },
    _vm._l(_vm.advertisements, function(advertisement) {
      return _c(
        "a",
        {
          key: advertisement.advertisement_picture_id,
          class: [{ noneEvent: advertisement.url == "" }],
          attrs: { href: advertisement.url, target: "_blank" }
        },
        [_c("b-carousel-slide", { attrs: { "img-src": advertisement.path } })],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }