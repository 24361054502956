<template>
    <div>
        <NavbarStyle />
        <Banner />
        <Features />
        <HomeImageCarousel /> <!-- 幻燈片 -->
        <Event />
        <News />
        <Partner />
        <FooterTop />
        <BacktopButton />
    </div>
</template>

<script>
    import NavbarStyle from 'components/DefaultHome/KECC/Common/NavbarStyle'
    import FooterTop from 'components/DefaultHome/KECC/Common/FooterTop?v=1.0'
    import BacktopButton from 'components/DefaultHome/KECC/Common/BacktopButton'
    import {
        WOW
    } from 'wowjs';

    import Banner from './Banner'
    import Features from './Features'
    import Event from './Event'
    import Partner from './Partner'
    import HomeImageCarousel from './HomeImageCarousel'
    import News from './News'

    export default {
        name: 'DefaultHomeView',
        metaInfo() {
            return {
                title: this.$t('home'),
            }
        },
        components: {
            NavbarStyle,
            Banner,
            Features,
            FooterTop,
            BacktopButton,
            Event,
            Partner,
            HomeImageCarousel,
            News
        },
        mounted() {
            new WOW({
                live: false
            }).init();
        }
    }
</script>
<style>
    p {
        font-size: 16px;
    }

    .h2,
    h2 {
        font-size: 1.8rem;
    }
</style>