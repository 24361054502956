<template>
  <div class="performance-area bottom-image">
    <div class="container">
      <div class="row">
        <div class="col-lg-12 col-sm-6 align-items-start pb-5">
          <h2 class="text-center news-title-color">{{ $t("Event.title") }}</h2>
        </div>
      </div>

      <div class="single-security-wrap owl-carousel owl-theme">
        <carousel
          ref="my-carousel"
          :autplay="true"
          :loop="true"
          :paginationEnabled="true"
          :autoplaySpeed="true"
          :autoplayTimeout="500"
          :perPage="showPageNumber"
          :navigationEnabled="true"
          navigationNextLabel=""
          navigationPrevLabel=""
        >
          <slide v-for="(item, index) in activityList" :key="index">
            <div class="single-blog-post">
              <div class="news-container">
                <div
                  class="news-image"
                  :style="{ backgroundImage: `url('${encodeURI(item.path)}')` }"
                ></div>
                <div class="overlay">
                  <div class="hoverText">
                    <div class="hover-date">
                      {{ item.card_time }}
                    </div>
                    <hr />
                    <div>
                      <h2 class="hover-title">
                        {{ item.event_title[apiLang] }}
                      </h2>

                      <router-link
                        :to="
                          `/visitors-exhibition/event/${item.activity_event_id}`
                        "
                      >
                        <button type="button" class="btn btn-warning mt-2">
                          READ MORE
                        </button>
                      </router-link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </slide>
        </carousel>
        <div class="row">
          <div class="col-lg-12 col-sm-6 align-items-start text-center">
            <a href="visitors-exhibition">
              <b-button variant="warning">{{
                $t("Event.moreButton")
              }}</b-button>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Carousel, Slide } from "vue-carousel";
import DateFormat from "dateformat";
export default {
  name: "News",
  components: {
    Carousel,
    Slide,
  },
  data() {
    return {
      page1: "primary",
      page2: "",
      showPageNumber: window.innerWidth >= 991 ? 4 : 1,
      apiUrl: `${process.env.VUE_APP_API_URL}/activity/`,
      activityList: [],
      apiLang: this.$i18n.apiLang,
    };
  },
  mounted() {
    const that = this;
    window.addEventListener("resize", () => {
      that.showPageNumber = window.innerWidth >= 991 ? 4 : 1;
    });
    that.getCardList();
  },
  methods: {
    getCardList() {
      let currentTime = DateFormat(new Date(), "yyyy-mm-dd");
      const api = `${this.apiUrl}/event_list`;
      let postParams = {
        activity_id: 1,
        page: 1,
        limit: "8",
        language_id: this.$i18n.locale,
        order_by: [
          {
            field: "start_time",
            method: "ASC",
          },
        ],
        filters: {
          time_range: {
            start: currentTime,
          },
        },
        image_width: 260,
      };
      let postConfig = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      this.axios.post(api, postParams, postConfig).then((response) => {
        let data = response["data"]["list"];
        for (let i = 0; i < data.length; i++) {
          data[i]["start_time"] = DateFormat(
            new Date(Date.parse(data[i]["start_time"].replace(/-/g, "/"))),
            "yyyy-mm-dd"
          );
          data[i]["end_time"] = DateFormat(
            new Date(Date.parse(data[i]["end_time"].replace(/-/g, "/"))),
            "yyyy-mm-dd"
          );
          data[i]["card_time"] =
            data[i]["start_time"] == data[i]["end_time"]
              ? data[i]["start_time"]
              : data[i]["start_time"] + " ~ " + data[i]["end_time"];
        }
        this.activityList = data;
      });
    },
  },
};
</script>

<style>
.VueCarousel {
  padding: 0 30px;
}

.VueCarousel-navigation-button {
  position: absolute;
  top: 50%;
  box-sizing: border-box;
  color: #000;
  text-decoration: none;
  appearance: none;
  border: none;
  background-color: transparent;
  padding: 0;
  cursor: pointer;
  outline: none;
}

.VueCarousel-navigation-next {
  background-image: url(~assets/project_images/img/b_r_arrow.png);
  height: 40px;
  background-size: contain;
  background-repeat: no-repeat;
}

.VueCarousel-navigation-prev {
  background-image: url(~assets/project_images/img/b_l_arrow.png);
  height: 40px;
  background-size: contain;
  background-repeat: no-repeat;
}

.VueCarousel-slide {
  padding: 0 5px;
}

.VueCarousel-pagination {
  display: none;
}
</style>
<style scoped>
.performance-area .container {
  max-width: 1400px;
}
.btn-warning {
  color: #212529;
}

.b-avatar-sm {
  width: 1rem;
  height: 1rem;
}
.bottom-image {
  padding-top: 50px;
  padding-bottom: 50px;
  background-image: none;
}

.btn:focus,
.btn:active {
  outline: none !important;
  box-shadow: none !important;
}

.news-title-color {
  font-size: 34px !important;
  font-weight: bold !important;
  color: #000033;
}

.news-more-style {
  color: #007fff;
}

.news-container {
  position: relative;
}

.news-images {
  display: block;
  width: auto;
  height: auto;
  min-height: 20vh !important;
  max-height: 20vh !important;
}

.news-images-mask {
  width: auto;
  height: auto;
  min-height: 35vh;
  max-height: 25vh;
  background-color: rgba(0, 0, 0, 1);
  background-blend-mode: multiply;
  background-size: cover;
  background-position: center center;
  z-index: 9;
}

.news-image {
  display: block;
  width: auto;
  height: auto;
  min-height: 250px;
  max-height: 250px;
  background-blend-mode: multiply;
  background-size: cover;
  background-position: center center;
  box-shadow: 0 0 8px rgb(0 0 0 / 10%);
  border: 1px solid rgb(245, 245, 245);
}

.news-title {
  font-size: 18px;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
}

.overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 0%;
  opacity: 0;
  transition: 0.5s ease;
}

.news-container:hover .overlay {
  opacity: 1;
  background-color: #000033;
  height: 100%;
}

.hover-title {
  font-size: 16px;
  color: white;
  padding-bottom: 5px;
}

.hover-content {
  color: white;
  position: absolute;
  bottom: 10px;
}

.hover-date {
  font-size: 22px;
  font-weight: bold;
}

.hoverText {
  padding: 30px 5px 15px 5px;
  width: 90%;
  height: 100%;
  color: white;
  font-size: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: left;
}

.hoverText hr {
  border: 1px solid #cccc00;
  margin-top: 0.5rem;
  margin-bottom: 0.8rem;
}

.performance-area
  .single-security-wrap.owl-theme
  .VueCarousel-dot-container
  .VueCarousel-dot.VueCarousel-dot--active {
  background-image: linear-gradient(to bottom right, #007fff, #007fff);
  width: 20px !important;
}

.btn-secondary {
  background-color: #d1d1d1;
}

.b-avatar {
  margin-right: 10px;
}

.performance-area::before {
  background-color: transparent;
}

.b-avatar {
  margin-top: 1% !important;
}
@media (max-width: 1200px) {
  /* Extra large devices (large desktops, 1200px and down) */
  .performance-area .container {
    max-width: 900px;
  }
}

@media (max-width: 992px) {
  /* Large devices (desktops, 992px and down) */
  .performance-area .container {
    max-width: 600px;
  }
}

@media (max-width: 768px) {
  /*  Medium devices (tablets, 768px and down) */
  .performance-area .container {
    max-width: 450px;
  }
}

@media (max-width: 576px) {
  /*  Small devices (landscape phones, 576px and down) */
  .performance-area .container {
    max-width: 400px;
  }
}

@media (max-width: 375px) {
  /*  Small devices (landscape phones, 576px and down) */
  .performance-area .container {
    max-width: 320px;
  }
  .news-image {
    min-height: 165px;
  }
}
</style>
