var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "performance-area bottom-image" }, [
    _c("div", { staticClass: "container" }, [
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-lg-12 col-sm-6 align-items-start pb-5" },
          [
            _c("h2", { staticClass: "text-center news-title-color" }, [
              _vm._v(_vm._s(_vm.$t("Event.title")))
            ])
          ]
        )
      ]),
      _c(
        "div",
        { staticClass: "single-security-wrap owl-carousel owl-theme" },
        [
          _c(
            "carousel",
            {
              ref: "my-carousel",
              attrs: {
                autplay: true,
                loop: true,
                paginationEnabled: true,
                autoplaySpeed: true,
                autoplayTimeout: 500,
                perPage: _vm.showPageNumber,
                navigationEnabled: true,
                navigationNextLabel: "",
                navigationPrevLabel: ""
              }
            },
            _vm._l(_vm.activityList, function(item, index) {
              return _c("slide", { key: index }, [
                _c("div", { staticClass: "single-blog-post" }, [
                  _c("div", { staticClass: "news-container" }, [
                    _c("div", {
                      staticClass: "news-image",
                      style: {
                        backgroundImage: "url('" + encodeURI(item.path) + "')"
                      }
                    }),
                    _c("div", { staticClass: "overlay" }, [
                      _c("div", { staticClass: "hoverText" }, [
                        _c("div", { staticClass: "hover-date" }, [
                          _vm._v(" " + _vm._s(item.card_time) + " ")
                        ]),
                        _c("hr"),
                        _c(
                          "div",
                          [
                            _c("h2", { staticClass: "hover-title" }, [
                              _vm._v(
                                " " +
                                  _vm._s(item.event_title[_vm.apiLang]) +
                                  " "
                              )
                            ]),
                            _c(
                              "router-link",
                              {
                                attrs: {
                                  to:
                                    "/visitors-exhibition/event/" +
                                    item.activity_event_id
                                }
                              },
                              [
                                _c(
                                  "button",
                                  {
                                    staticClass: "btn btn-warning mt-2",
                                    attrs: { type: "button" }
                                  },
                                  [_vm._v(" READ MORE ")]
                                )
                              ]
                            )
                          ],
                          1
                        )
                      ])
                    ])
                  ])
                ])
              ])
            }),
            1
          ),
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              {
                staticClass: "col-lg-12 col-sm-6 align-items-start text-center"
              },
              [
                _c(
                  "a",
                  { attrs: { href: "visitors-exhibition" } },
                  [
                    _c("b-button", { attrs: { variant: "warning" } }, [
                      _vm._v(_vm._s(_vm.$t("Event.moreButton")))
                    ])
                  ],
                  1
                )
              ]
            )
          ])
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }