import { render, staticRenderFns } from "./Features.vue?vue&type=template&id=2f29a092&scoped=true&"
import script from "./Features.vue?vue&type=script&lang=js&"
export * from "./Features.vue?vue&type=script&lang=js&"
import style0 from "./Features.vue?vue&type=style&index=0&id=2f29a092&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2f29a092",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/builds/ctweb/fontend/kecc/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('2f29a092')) {
      api.createRecord('2f29a092', component.options)
    } else {
      api.reload('2f29a092', component.options)
    }
    module.hot.accept("./Features.vue?vue&type=template&id=2f29a092&scoped=true&", function () {
      api.rerender('2f29a092', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/DefaultHome/KECC/Home/Features.vue"
export default component.exports